<template>
  <v-app-bar bottom app height="48" color="white">
    <router-link to="/" class="mr-4"
      ><v-img src="@/assets/images/dashboard.png" max-width="25"
    /></router-link>
    <router-link
      v-if="$route.name === 'pasien.new' || $route.name === 'pasien.edit'"
      to="/pasien"
      class="mr-4"
      ><v-img src="@/assets/images/pasien.png" max-width="25"
    /></router-link>
    <router-link
      v-if="
        $route.name === 'farmasi.new' ||
        $route.name === 'farmasi.edit' ||
        $route.name === 'antrian.new' ||
        $route.name === 'antrian.edit'
      "
      to="/farmasi"
      class="mr-4"
      ><v-img src="@/assets/images/unit.png" max-width="25"
    /></router-link>
    <router-link
      v-if="$route.name === 'gizi.new' || $route.name === 'gizi.edit'"
      to="/gizi"
      class="mr-4"
      ><v-img src="@/assets/images/unit.png" max-width="25"
    /></router-link>
    <v-spacer></v-spacer>
    <v-toolbar-title class="mr-n4">
      <v-sheet
        width="165"
        height="48"
        class="px-2 py-1"
        style="border-left: 1px solid #eee"
      >
        <div
          style="font-size: 10px"
          class="
            d-flex
            justify-space-between
            align-center
            grey--text
            text--darken-2 text-uppercase
          "
        >
          <img
            src="@/assets/images/status.png"
            style="width: 11px; height: 11px"
          />
          <span class="nexa-black mt-1">{{ currentTime[1] }}</span>
          <img
            src="@/assets/images/plus.png"
            style="width: 16px; height: 10px"
          />
        </div>
        <div
          class="nexa-black text-center grey--text"
          style="font-size: 32px; margin-top: 2px"
        >
          {{ currentTime[0] }}
        </div>
      </v-sheet>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import moment from "moment";
moment.locale("id");
export default {
  name: "FooterBar",
  data() {
    return {
      currentTime: [],
    };
  },
  computed: {
    getCurrentDate() {
      return new Date();
    },
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = {
        0: moment().format("HH:mm:ss"),
        1: moment().format("dddd, DD MMM YYYY"),
      };
    },
  },
  created() {
    this.currentTime = {
      0: moment().format("HH:mm:ss"),
      1: moment().format("dddd, DD MMM YYYY"),
    };
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
  },
};
</script>
