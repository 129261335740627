import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("../views/Login.vue"),
    },
    {
      path: "/",
      name: "index",
      meta: {
        auth: true,
      },
      component: () => import("../views/Form/Index.vue"),
    },
    {
      path: "/antrian",
      name: "antrian",
      meta: {
        auth: true,
      },
      component: () => import("../views/Form/IndexStatus.vue"),
    },
    {
      path: "/form",
      name: "form",
      meta: {
        auth: true,
      },
      component: () => import("../views/Form/Form.vue"),
    },
    {
      path: "/form/:id",
      name: "form.edit",
      meta: {
        auth: true,
      },
      component: () => import("../views/Form/Form.vue"),
      props: true,
    },
    {
      path: "/setting",
      name: "setting",
      meta: {
        auth: true,
      },
      component: () => import("../views/Setting.vue"),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === "index" && store.state.settings.antrian)
    next({ name: "antrian" });
  else next();
});

export default router;
