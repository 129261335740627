<template>
    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        :timeout="timeout"
        :right="toast ? false : right"
        :top="toast ? true : top"
        :rounded="toast && 'pill'"
        style="bottom:30px;"
        :color="toast ? color : ''"
        :content-class="toast ? 'text-center' : ''"
        :text="transparent"
    >
        {{ text }}

        <template v-if="!toast" v-slot:action="{ attrs }">
            <v-btn v-if="action" :color="color" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    data() {
        return {
            multiLine: false,
            snackbar: false,
            text: "Success",
            toast: false,
            timeout: 3000,
            color: "success",
            right: false,
            top: false,
            transparent: false,
            action: true
        }
    },
    methods: {
        show(data) {
            this.multiLine = data.multiLine || false
            this.snackbar = data.snackbar || true
            this.toast = data.toast ?? false
            this.text = data.text || "Success"
            this.timeout = data.timeout || 3000
            this.color = data.color || "success"
            this.right = data.right ?? true
            this.top = data.top ?? false
            this.transparent = data.transparent ?? false
            this.action = data.action ?? true
        }
    }
};
</script>
