import moment from "moment";

export default {
  install(Vue) {
    Vue.mixin({
      created() {},
      methods: {
        is_mobile() {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            return true;
          } else {
            return false;
          }
        },
        calculateAge: function (date) {
          // console.log(this.datepicker);
          let ageMS =
            Date.parse(Date()) -
            Date.parse(date);
          let age = new Date();
          age.setTime(ageMS);
          let ageYear = age.getFullYear() - 1970;
          let ageMonth = age.getMonth(); // Accurate calculation of the month part of the age
          let ageDay = age.getDate(); // Approximate calculation of the day part of the age
          return ageYear < 1
            ? ` (${ageMonth == 0 ? "" : ageMonth + "bl "}${ageDay}hr)`
            : ageYear >= 1 && ageYear <= 3
            ? ` (${ageYear}th ${
                ageMonth == 0 ? "" : ageMonth + "bl "
              }${ageDay}hr)`
            : ` (${ageYear}th)`;
        },
        formatDate: function (date, format1, format2) {
          moment.locale("id");
          if (date) {
            return moment(date, format2 || ["DD-MM-YYYY", "YYYY-MM-DD", "x"], 'id').format(
              format1 || "DD MMM YYYY"
            );
          } else {
            return null;
          }
        },
				trackEvent(category, action, label, value) {
					this.$gtm.trackEvent({
						event: 'grow-app', // Event type [default = 'interaction'] (Optional)
						category: category || "RS ID-GROW",
						action: action || "click",
						label: label || "Label Pasien",
						value: value || 1,
						// noninteraction: false, // Optional
					});
				},
      },
    });
  },
};
