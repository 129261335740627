<template>
  <v-dialog tile persistent v-model="dialog" max-width="400">
    <v-card tile style="background: #fff !important; color: #363636 !important;">
      <v-card-title/>
      <v-card-text>
        <slot/>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          depressed
          small
          outlined
          class="grey--text col"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          small
          color="#00cccc"
          class="white--text col"
          @click="accept"
        >
          {{ confirm }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
      confirm: { type: String, default: 'Confirm'},
  },
  data () {
    return {
      dialog: false,
      resolve : null,
      reject : null,
    }
  },
  methods: {
    open(){
      return new Promise((resolve, reject)=>{
        this.resolve = resolve
        this.reject = reject
        this.dialog = true
      })
    },
    accept() {
      this.resolve();
      this.dialog = false;
    },
    cancel() {
      this.dialog = false;
    },
  },
}
</script>
