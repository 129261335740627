<template>
  <v-app>
    <template v-if="$route.name === 'display' || $route.name === 'activation'">
      <router-view class="mt-7" />
    </template>
    <template v-else-if="$route.name === 'label.print'">
      <router-view />
    </template>
    <template v-else>
      <nav-bar />
      <keep-alive max="2">
        <router-view
          v-if="$route.name === 'index'"
          class="mt-7"
          :key="$route.name + '_' + componentKey"
        />
        <router-view
          v-else
          :key="$route.name + '_' + componentKey"
          class="mt-7"
          @needUpdate="forceRerender"
          @showProgress="() => (overlay = !overlay)"
        />
      </keep-alive>
      <footer-bar />
    </template>
    <SnackBar ref="snackbar" />
    <v-overlay style="z-index: 99999" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar color="grey darken-1" :value="updateExists" :timeout="-1">
      Update tersedia
      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          depressed
          small
          v-bind="attrs"
          @click="refreshApp"
        >
          <v-icon left> mdi-update </v-icon>
          Update
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";
import SnackBar from "@/components/SnackBar";
import { App as CapacitorApp } from "@capacitor/app";
import Pull from "./plugins/pull.js";
import update from "./mixins/update";

export default {
  name: "home",
  components: {
    FooterBar,
    NavBar,
    SnackBar,
  },
  mixins: [update],
  data() {
    return {
      componentKey: 0,
      backBtn: 0,
      overlay: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loader;
    },
  },
  mounted() {
    this.$root.snackbar = this.$refs.snackbar;
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        this.backBtn += 1;
        setTimeout(() => {
          this.backBtn = 0;
        }, 2000);
        this.$root.snackbar.show({
          color: "secondary",
          timeout: "2000",
          text: "Tekan lagi untuk keluar",
          transparent: true,
          action: false,
        });
        if (this.backBtn > 1) {
          CapacitorApp.exitApp();
        }
      } else {
        window.history.back();
      }
    });

    Pull.init({
      // mainElement: '',
      triggerElement: ".v-application--wrap",
      instructionsPullToRefresh:
        '<div class="outer"><span class="loader"></span></div>',
      instructionsReleaseToRefresh:
        '<div class="outer"><span class="loader"></span></div>',
      instructionsRefreshing:
        '<div class="outer"><span class="loader"></span></div>',
      distThreshold: 120,
      distMax: 150,
      distReload: 80,
      onRefresh: () => this.forceRerender(),
    });
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
  },
  watch: {
    loading() {
      this.overlay = !this.overlay;
    },
  },
};
</script>

<style>
.ptr--ptr {
  position: absolute;
  background: none;
  z-index: 999;
}
.loader {
  background: white;
  width: 24px;
  height: 24px;
  /* margin: 5px; */
  border: 3px solid #464646;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.outer {
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 2px 1px #bbbbbbb0;
  display: inline-flex;
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
